///* ========================================================================
//   #FONT-FACE
//   ======================================================================== */

// Mixin to add font-face

@mixin font-face($font-name, $font-file, $font-weight: normal, $font-style: normal) {
    @font-face {
        font-family: $font-name;
        src: url('../fonts/'+$font-file+'.woff2') format('woff2'), url('../fonts/'+$font-file+'.woff') format('woff');
        font-weight: $font-weight;
        font-style: $font-style;
        font-display: swap;
    }
}