///* ========================================================================
//   #LAZYLOAD
//   ======================================================================== */

// Classes for lazyloaded images

.lazyload,
.lazyloading {
	opacity: 0;
}
.lazyloaded {
	opacity: 1;
	transition: opacity 300ms;
}