///* ========================================================================
//   #PLACEHOLDER
//   ======================================================================== */

// Mixin to add color to placeholders

@mixin placeholder($color) {
    &::-webkit-input-placeholder {
       color: $color;
       opacity: 1;
    }

    &:-moz-placeholder {
       color: $color;
       opacity: 1;
    }

    &::-moz-placeholder {
       color: $color;
       opacity: 1;
    }

    &:-ms-input-placeholder {
       color: $color;
       opacity: 1;
    }
}