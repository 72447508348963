
/* ==========================================================================
   #INPUT
   ========================================================================== */

input, select {
    font-family: inherit;
    @include placeholder(#666);
}

select {
    appearance: none;
    padding-right: 40px;
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    background-size: 18px;
    background-image: url('../images/components/inputs/icon-chevron-down.svg');
    cursor: pointer;

    &::-ms-expand {
        display: none;
    }
}