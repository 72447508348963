.s-cms-content {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 24px;
    @include mq($from: desktop) {
        margin-bottom: 40px;
    }

    &:last-child {
        margin-bottom: 0;
    }

    p {
        margin: 20px 0 0;

        &:first-child {
            margin-top: 0;
        }
    }     

    &--center {
        p {
            text-align: center;
        }

        h3 {
            text-align: center;
        }
    }

    strong {
        font-weight: 700;
        color: $color-purple;
    }
    

    h3 {
        font-family: $font-poppins;
        margin: 30px 0 20px;
        font-weight: 700;
        $ifz: 20px;
        $ffz: 24px;
        font-size: $ifz;
        &:first-child {
            margin-top: 0;
        }
        @include mq($from: tablet) {
            font-size: font-increment($ifz, $ffz, 1);
        }
        @include mq($from: desktop) {
            font-size: font-increment($ifz, $ffz, 2);
        }
        @include mq($from: wide) {
            font-size: $ffz;
        }

        @at-root .s-cms-content--faqs h3 {
            font-size: 16px;
            @include mq($from: tablet) {
                font-size: 17px;
            }
            @include mq($from: desktop) {
                font-size: 18px;
            }
            @include mq($from: wide) {
                font-size: 18px;
            }
        }

        strong {
            color: $color-cyan;
        }
    }

    a:not([class]) {
        text-decoration: underline;
        font-weight: 700;
        color: $color-purple;
        transition: color 0.3s ease;

        &:hover {
            color: $color-purple;
            transition: color 0.3s ease;
        }
    }

    img, video, figure {
        max-width: 570px;
        height: auto;
        display: block;
        margin: 20px auto;
        width: 100%;
        box-shadow: $global-box-shadow;

        &.native {
            max-width: 100%;
        }
    }

    figure > img {
        width: 100%;
        height: auto;
        display: block;
        margin: 0;
        box-shadow: none;
    }

    ul:not([class]) {
        padding-left: 20px;
        margin: 10px 0 20px;
        &:first-child {
            margin-top: 0;
        }
        @include mq($from: desktop) {
            margin: 30px 0;
            padding-left: 60px;
        }

        li {
            margin-bottom: 15px;
            @include mq($from: desktop) {
                margin-bottom: 20px;
            }
            
            &::marker {
                color: $color-purple;
            }
        }
    }

    ol:not([class]) {
        padding-left: 20px;
        margin: 10px 0 20px;
        &:first-child {
            margin-top: 0;
        }
        @include mq($from: desktop) {
            margin: 30px 0;
            padding-left: 60px;
        }

        li {
            margin-bottom: 15px;
            @include mq($from: desktop) {
                margin-bottom: 20px;
            }
            
            &::marker {
                color: $color-purple;
                font-weight: 700;
            }
        }
    }
}