/* ==========================================================================
   #LIST-BARE
   ========================================================================== */

.o-list-bare {
    list-style: none;
    margin: 0;
    padding: 0;

    &__item {
        margin-left: 0;
    }
}