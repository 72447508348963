
/* ==========================================================================
   #BUTTON
   ========================================================================== */

button {
    font-family: inherit;
    border: none;
    padding: 0;
    line-height: inherit;
    cursor: pointer;
}